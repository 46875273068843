import React, { createContext, useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

export const FlowContext = createContext();

export const FlowProvider = ({ children }) => {

    const [flowIndex, setFlowIndex] = useState(null);

    const randomFlowDefiner = () => {
        return Math.random() > 0.5 ? 0 : 1;
    }

    useEffect(() => {
        setFlowIndex(randomFlowDefiner());
    }, [])

    useEffect(() => { console.log({ flowIndex }) }, [flowIndex])

    

    if (flowIndex === null) {
        return <Box sx={{ display: 'flex', width: '100%', heigh: '100%', justifyContent: "center", alignItems: "center" }}>
            <CircularProgress />
        </Box>
    }
    return (
        <FlowContext.Provider value={{ flowIndex }}>
            {children}
        </FlowContext.Provider>
    );
};
