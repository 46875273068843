/* import {
	API_KEY,
	API_SECRET,
	PUBLISHER_DEAL_UID,
	SENDER_EMAIL,
	SENDER_NAME,
} from "$env/static/private";
import { PUBLIC_CAMPAIGN_UID, PUBLIC_CORE_URL } from "$env/static/public";

import { decrypt } from "$lib/encryption";
import { selectTemplate } from "$lib/util"; */

export const sendLead = async (data) => {
	const url = `https://leadmgmthq.com/api/public/integration/${process.env.REACT_APP_PUBLISHER_DEAL_UID}`;
	return fetch(url, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			"Access-Control-Allow-Origin": "*",
		},
		body: JSON.stringify(data),
	});
};

export const sendFormData = async (data) => {
	const url = "https://cv3.juksr.com/v4/api/set";
	const formData = new FormData();

	formData.append("Full Name", data.name);
	formData.append("Email", data.email);
	formData.append("Phone", data.phone);
	formData.append("SelectedCampaigns", data.SelectedCampaigns);
	formData.append("aff_id", data.aff_id);
	formData.append("page_name", data.page_name);

	return fetch(url, {
		method: "POST",
		headers: {
			"Content-Type": "multipart/form-data",
			"Access-Control-Allow-Origin": "*",
		},
		body: formData,
	});
};

export const makeRequest = (endpoint, method = "POST", body) => {
	return new Promise(async (resolve, reject) => {
		const requestOptions = {
			method: method,
			headers: {
				"Content-Type": "application/json",
				"Access-Control-Allow-Origin": "*",
			},
			body: JSON.stringify(body),
		};
		await fetch(endpoint, requestOptions)
			.then((response) => response.json())
			.then((data) => {
				resolve(data);
			})
			.catch((err) => {
				reject({ success: false });
			});
	});
};
