import React, { useEffect, useState } from "react";
import "./Offer.scss";
import Checkbox from "@mui/material/Checkbox";

export default function Offer({ offer, handleChange }) {
	const [checked, setChecked] = useState(false);

	return (
		<div className={"offer-item" + (checked ? " checked" : " ")} onClick={() => { handleChange(offer.id); setChecked(!checked) }}>
			{/* <Checkbox
				checked={checked}
				onChange={() => {
					handleChange(offer.id)
					setChecked(!checked)
				}}
				className="customCheckbox"
				sx={{
					color: "#222",
					"&.Mui-checked": {
						color: "#222",
					},
				}}
			/> */}
			<img alt="offer" className="offerImage" src={offer.imageSrc} />
			<div className="offer-info">
				<span className="offerTitle">{offer.title}</span>
				<span className="offerText">{offer.content}</span>
			</div>
		</div>
	);
}
