import React, { useEffect } from "react";
import HomePage from "./Pages/HomePage";
import SurveyPage from "./Pages/SurveyPage";
import OffersPage from "./Pages/OffersPage";
import CompletedPage from "./Pages/CompletedPage";
import "./App.scss";
import { BrowserRouter, Link, Route, Routes } from "react-router-dom";
import Modal from "react-modal";
import FinalPage from "./Pages/FinalPage";

export default function App() {
	Modal.setAppElement("#surveyApp");
	const [successModalOpen, setSuccessModalOpen] = React.useState(false);
	const [selectedOffer, setSelectedOffer] = React.useState({});

	useEffect(() => {
		const appHeight = () => {
			const doc = document.documentElement;

			doc.style.setProperty("--app-height", `${window.innerHeight}px`);
			doc.style.setProperty("--app-width", `${window.innerWidth}px`);
		};
		window.addEventListener("resize", appHeight);
		appHeight();
		return () => {};
	}, []);

	return (
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<HomePage />} />
				<Route path="/survey" element={<SurveyPage />} />
				<Route
					path="/offers/:id"
					element={
						<OffersPage
							setSuccessModalOpen={setSuccessModalOpen}
							setSelectedOffer={setSelectedOffer}
						/>
					}
				/>
				<Route
					path="/final"
					element={
						<FinalPage
							setSuccessModalOpen={setSuccessModalOpen}
							setSelectedOffer={setSelectedOffer}
						/>
					}
				/>
				<Route
					path="/completed"
					element={
						<CompletedPage
							successModalOpen={successModalOpen}
							setSuccessModalOpen={setSuccessModalOpen}
						/>
					}
					selectedOffer={selectedOffer}
				/>
			</Routes>
		</BrowserRouter>
	);
}
